import React from "react";
import { TimerControl } from "../../types/timer.type";
import { TimerScreenData } from "../../types/screen.type";
import { HorTvbd } from "../../components/HorTvbd/HorTvbd";
import { StartingClassIn } from "../../components/StartingClassIn30Seconds/StartingClassIn30Seconds";
import { NextClassHorizontalLogoWorkout } from "../../components/NextClassHorizontalLogoWorkout/NextClassHorizontalLogoWorkout";


interface TimerProps {
  timerScreenData?: TimerScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
}

export const Timer: React.FC<TimerProps> = ({ timerScreenData, timerControlRef }): React.JSX.Element => {

  let showFinish = timerScreenData?.type === "ending" ? true : false;

  if (!timerScreenData) {
    return (
      <div style={{
        backgroundImage: "url(/img/horizontal_loading.png)",
        width: "1920px",
        height: "1080px",
      }}>
      </div>
    );
  }

  {

    if (!timerScreenData.isWorkout) {
      return (
        <NextClassHorizontalLogoWorkout screenData={timerScreenData} timerControlRef={timerControlRef}></NextClassHorizontalLogoWorkout>
      );
    } else {
      if (timerScreenData.isWorkoutStarting) {
        return (
          <StartingClassIn initialTotalTime={timerScreenData.progressCircle?.initialTotalTime} color={timerScreenData.progressCircle?.color} timerControlRef={timerControlRef}  ></StartingClassIn>
        );
      } else {
        return (
          <HorTvbd workoutName={timerScreenData.workoutName ?? ""}
            totalTime={timerScreenData.totalTime ?? 0}
            set={timerScreenData.set ?? 0}
            totalSets={timerScreenData.totalSets ?? 0}
            hydrate_active={timerScreenData.hydrate_active || 0}
            hydrate_total={timerScreenData.hydrate_total || 0} 
            type={timerScreenData.type}
            showFinish={showFinish}
            timerControlRef={timerControlRef} />
        );
      }
    }
  }
};
