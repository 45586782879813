import React, { useEffect, useState } from "react";
import { ExerciseStationVideo } from "../../../types/exercise.type";
import "./Videos.css";
import { Video } from ".";

type VideosProps = {
    exerciseStationVideos?: ExerciseStationVideo[];
    showStations?: boolean;
};

const Videos: React.FC<VideosProps> = ({ exerciseStationVideos = [], showStations = false }) => {
 
    const [applyOverlay, setApplyOverlay] = useState(false);

    const isSmallLayout = exerciseStationVideos.length <= 3;

    useEffect(() => {
        if (exerciseStationVideos.length > 6) {
            setApplyOverlay(true);
        } else {
            setApplyOverlay(false);
        }
    }, [exerciseStationVideos]);

    // Remove useMemo and directly render the mapped elements
    const renderedVideos = exerciseStationVideos.map((exercise, index) => {
        const containerClass = isSmallLayout ? "small-container" : "large-container";
        const contentClass = isSmallLayout ? "small-content" : "content-2";
        const textClass = isSmallLayout ? "small-text" : "text-5";

        return (
            <div className={`video-container ${containerClass}`} key={index}>
                <div className={contentClass}>
                    {showStations && isSmallLayout && (
                        <div className="workout-movement small-text">
                            <div className="text">{exercise.station_number}</div>
                        </div>
                    )}
                    <div className="workout">
                        <div className="video">
                            <Video url={exercise.video} />
                        </div>
                    </div>
                    {showStations && !isSmallLayout && (
                        <div className={`workout-movement ${applyOverlay ? 'apply-overlay' : ''}`}>
                            <div className={textClass}>{exercise.station_number}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    });

    return (
        <div className={`videos ${isSmallLayout ? "small-layout" : "large-layout"}`}>
            {renderedVideos}
        </div>
    );
};

export default Videos;