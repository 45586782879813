{
    "exercise_type": {
      "Strength": "Strength",
      "Cardio": "Cardio",
      "Mobility": "Mobility",
      "default": "Exercise"
    },
    "period": {
      "morning": "Morning",
      "afternoon": "Afternoon",
      "night": "Night",
      "default": "Morning"
    },
    "week_day_short": {
      "Monday": "Monday",
      "Tuesday": "Tuesday",
      "Wednesday": "Wednesday",
      "Thursday": "Thursday",
      "Friday": "Friday",
      "Saturday": "Saturday",
      "Sunday": "Sunday",
      "default": "Monday"
    },
    "today": "Today",
    "stay": {
      "station": "Stay in this Station",
      "pod": "Stay in this Pod",
      "station_move": "Move to next Station",
      "pod_move": "Move to next Pod",
      "set_station": "After this Set, stay in this Station",
      "set_station_move": "After this Set, move to next Station",
      "set_pod_move": "After this Set, move to next Pod",
      "get_ready": "Get Ready"
    },
    
      "sets_number_one": "{{count}} set left",
      "sets_number_other": "{{count}} sets left",
    "stations": "Stations",
    "sets": "Sets",
    "finish": {
      "finish": "Workout completed."
    },
    "hydrate": {
      "recover": "Recover"
    },
    "next_class": "Next Class"
  }