import React, { useEffect } from "react";
import "./Day.css";
import { DateTimeUtils } from "../../utils/datetime.utils";

// Define the type for props
interface DayProps {
  dateLabel: string;
  time: string;
  onLoad?: () => void;
}

// Use the props in the component
const Day: React.FC<DayProps> = ({ dateLabel, time, onLoad }: DayProps): React.JSX.Element => {

  useEffect(() => {
    if (onLoad) {
      onLoad(); // Notify that this component has finished loading
    }
  }, [onLoad]);

  return (
    <div className="workout-day">
      <div className="label">{dateLabel}</div>
      <div className="text-wrapper">|</div>
      <div className="text-wrapper">{DateTimeUtils.formatTimeToHHMM(time)}</div>
    </div>
  );
};

export default Day;