import React from "react";
import "./style.css";
import { ProgressCircle } from "../ProgressCircle";
import { TimerScreenData } from "../../types/screen.type";
import { TimerControl } from "../../types/timer.type";
import { useTranslation } from "react-i18next";

interface NextClassHorizontalLogoWorkoutProps {
  screenData?: TimerScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
}

export const NextClassHorizontalLogoWorkout: React.FC<NextClassHorizontalLogoWorkoutProps> = ({ screenData, timerControlRef }): JSX.Element => {
  
  const { t } = useTranslation();

  
  return (
    <div className="next-class">
      <div className="container-wrapper">
        <div className="container">
          <div className="content">
            <div className="title-wrapper">
              <div className="title">
                <img className="dumbbell1" alt="Workout Icon" src="/img/goal.svg" />
                <div className="text">{t("next_class")}</div>
              </div>
            </div>

            <ProgressCircle initialTotalTime={screenData?.progressCircle?.initialTotalTime ?? 0} color={screenData?.progressCircle?.color} ref={timerControlRef} />

            <div className="title-wrapper">
              <div className="PT-container">
                {screenData?.personalTrainer?.photo && (
                  <div className="icon">
                    <img className="rectangle" alt="Personal Trainer" src={screenData?.personalTrainer?.photo} />
                  </div>
                )}
                <div className="text-2">
                  <div className="text-3">{screenData?.personalTrainer?.name}</div>
                  <div className="text-4">{screenData?.personalTrainer?.job_title}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="logo-workout">
            <div className="overlap">
              <div className="logo">
                <div className="lgo-wrapper">
                  <img className="lgo" alt="Workout Logo" src={screenData?.workoutImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};