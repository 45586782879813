import React, { useEffect, useRef, useState } from "react";
import "./Videos.css";

type VideoProps = {
    url?: string;
    onLoad?: () => void;
    maxRetries?: number; // Optional: maximum number of retries on failure
};

const Video: React.FC<VideoProps> = ({ url, onLoad, maxRetries = 3 }: VideoProps): React.JSX.Element => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [retryCount, setRetryCount] = useState(0);

    // Handle when the URL changes
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.play().catch(() => {
                //console.log("Autoplay prevented, trying to load again...");
            });
        }
    }, [url]);

    // Effect to handle retries on video error
    useEffect(() => {
        if (retryCount > maxRetries) {
            console.warn("Max retries exceeded for video loading.");
            return;
        }
    }, [retryCount, maxRetries]);

    // Event handler for when the video loads successfully
    const handleLoadedData = () => {
        if (onLoad) {
            onLoad(); // Notify the parent component
        }
    };

    const handleError = () => {
        const video = videoRef.current;
    
        if (video?.error) {
            const error = video.error;
    
            // Log the error details
            switch (error.code) {
                case error.MEDIA_ERR_ABORTED:
                    console.error("The user aborted the video playback.");
                    break;
                case error.MEDIA_ERR_NETWORK:
                    console.error("A network error caused the video download to fail.");
                    break;
                case error.MEDIA_ERR_DECODE:
                    console.error("The video playback was aborted due to a corruption problem or because the video used features your browser did not support.");
                    break;
                case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    console.error("The video could not be loaded because the format is not supported.");
                    break;
                default:
                    console.error("An unknown error occurred.");
                    break;
            }
    
            // Log the full error object for further debugging
            console.error("Error code:", error.code);
            console.error("Error message:", error.message || "No error message provided");
        }
    
        // Retry logic
        if (retryCount < maxRetries) {
            console.warn("Video failed to load, retrying...");
            setTimeout(() => {
                setRetryCount((prev) => prev + 1); // Increment retry count
                video?.load(); // Reload video
                video?.play().catch(() => {
                    console.log("Autoplay prevented, retrying...");
                });
            }, 500); // Retry after 500ms
        } else {
            console.warn("Max retries exceeded for video loading.");
        }
    };

    return (
        <video
            ref={videoRef}
            width="100%"
            autoPlay
            loop
            muted
            controls={false}
            onLoadedData={handleLoadedData} // Video loaded successfully
            onError={handleError} // Video loading failed
        >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

export default Video;