import { Booking, BookingClasses, BookingData, BookingFiltered } from '../types/booking.type';

export class BookingService {

    static toBookingData(data: BookingClasses): BookingData[] {
        if (!data) {
            return [];
        }
    
        // Sort data by date in ascending order (oldest to newest)
        data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
        // Get the most recent date and the oldest date in the data
        const mostRecentDate = new Date(data[data.length - 1].date); // Newest date
        const oldestDate = new Date(data[0].date); // Oldest date
    
        // Create a map of existing dates to their data for quick lookup
        const dateMap = new Map<string, BookingData>();
        data.forEach(entry => {
            const firstWorkoutImage = entry.class_schedules[0]?.workout?.image || null;
            const startTimes = entry.class_schedules.map(schedule => schedule.start_time);
    
            dateMap.set(entry.date, {
                date: entry.date,
                class_start_times: startTimes,
                image: firstWorkoutImage
            });
        });
    
        // Create a list to store the result of complete data
        let completeData: BookingData[] = [];
        let currentDate = new Date(oldestDate); // Start from the oldest date
    
        // Keep filling up to 7 days
        while (completeData.length < 7) {
            const dateString = currentDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    
            // If data exists for this date, use it; otherwise, create an empty entry
            if (dateMap.has(dateString)) {
                completeData.push(dateMap.get(dateString)!);
            } else {
                completeData.push({
                    date: dateString,
                    class_start_times: [],
                    image: null
                });
            }
    
            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        return completeData;
    }

    // Method to filter class times into morning, afternoon, and night periods
    static filterClassTimesByPeriod(schedules: BookingData[]): BookingFiltered[] {
        return schedules.map(schedule => {
            const morning: string[] = [];
            const afternoon: string[] = [];
            const night: string[] = [];

            schedule.class_start_times.forEach(time => {
                const [hours, minutes] = time.split(':').map(Number);
                const totalMinutes = hours * 60 + minutes;

                if (totalMinutes >= 6 * 60 && totalMinutes < 12 * 60) {
                    morning.push(time);
                } else if (totalMinutes >= 12 * 60 && totalMinutes < 18 * 60) {
                    afternoon.push(time);
                } else {
                    night.push(time);
                }
            });

            // Sorting the time arrays
            const sortTimes = (times: string[]) => {
                return times.sort((a, b) => {
                    const [aHours, aMinutes] = a.split(':').map(Number);
                    const [bHours, bMinutes] = b.split(':').map(Number);
                    return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
                });
            };

            return {
                date: schedule.date,
                morning: sortTimes(morning),
                afternoon: sortTimes(afternoon),
                night: sortTimes(night),
                image: schedule.image
            };
        });
    }

    // Method to transform filtered schedule data into the final schedule format
    static transformScheduleData(data: BookingFiltered[]): Booking {
        const daysCount = 7; // Fixed to always output a 7-day week
        const result: Booking = {
            morning: [],
            afternoon: [],
            night: []
        };
    
        // Sort data by date in descending order
        data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    
        //
        data = data.reverse();

        // Initialize the number of rows needed for each time period
        let maxMorningSlots = 0;
        let maxAfternoonSlots = 0;
        let maxNightSlots = 0;
    
        // Determine the maximum number of slots needed for each period
        for (let i = 0; i < Math.min(data.length, daysCount); i++) {
            const day = data[i];
            maxMorningSlots = Math.max(maxMorningSlots, day.morning.length);
            maxAfternoonSlots = Math.max(maxAfternoonSlots, day.afternoon.length);
            maxNightSlots = Math.max(maxNightSlots, day.night.length);
        }
    
        // Create the arrays for each time period
        for (let i = 0; i < maxMorningSlots; i++) {
            result.morning.push(Array(daysCount).fill(""));
        }
        for (let i = 0; i < maxAfternoonSlots; i++) {
            result.afternoon.push(Array(daysCount).fill(""));
        }
        for (let i = 0; i < maxNightSlots; i++) {
            result.night.push(Array(daysCount).fill(""));
        }
    
        // Fill in the data into the result
        for (let i = 0; i < Math.min(data.length, daysCount); i++) {
            const day = data[i];
    
            // Fill morning slots
            for (let j = 0; j < day.morning.length; j++) {
                result.morning[j][i] = day.morning[j] || "";
            }
    
            // Fill afternoon slots
            for (let j = 0; j < day.afternoon.length; j++) {
                result.afternoon[j][i] = day.afternoon[j] || "";
            }
    
            // Fill night slots
            for (let j = 0; j < day.night.length; j++) {
                result.night[j][i] = day.night[j] || "";
            }
        }
    
        // Remove trailing empty arrays if not needed
        result.morning = result.morning.filter(slot => slot.some(time => time !== ""));
        result.afternoon = result.afternoon.filter(slot => slot.some(time => time !== ""));
        result.night = result.night.filter(slot => slot.some(time => time !== ""));
    
        return result;
    }
    
    
}

