import React, { useState, useEffect } from "react";
import "./style.css";

interface BatteryCharging2Props {
  blink: boolean;
}

export const BatteryCharging2: React.FC<BatteryCharging2Props> = ({
  blink = false,
}): JSX.Element => {
  const [isBlinking, setIsBlinking] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (blink) {
      interval = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, 500); // Adjust the interval duration (500ms) as needed
    } else {
      setIsBlinking(false);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [blink]);

  return (
    <div className="battery-charging">
      <img
        className="vector"
        alt="Vector"
        src={isBlinking ? "/img/battery-charging-empty.svg" : "/img/battery-charging.svg"}
      />
    </div>
  );
};