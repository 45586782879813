import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import log from "./utils/logger.utils";
import { CoreService } from './services/core.service';
import { RenderService } from "./services/render.service";
import { changeLanguage } from './utils/language.utils';
import i18n from './locales/i18n';
import { Exercise } from "./pages/Exercise/Exercise";
import { BookingScreenData } from "./types/booking.type";
import { Booking } from "./pages/Booking/HorBooking";
import { HorMovement } from "./pages/Movement/Movement";
import { MovementScreenData } from "./types/movement.type";
import { TvTypeEnum } from "./types/tvs.type";
import { TimerScreenData, WorkoutScreenData } from "./types/screen.type";
import { TimerControl, TimerControlAction } from "./types/timer.type";
import { Timer } from "./pages/Timer/Timer";

import { t, use } from "i18next";
import { CoreEvents, MqttEvents } from "./types/events.type";
import { HorTvbd } from "./components/HorTvbd/HorTvbd";
import { HorNextClass } from "./components/HorNextClass/HorNextClass";


const App: React.FC = () => {

  const logger = log.getLogger('App');

  const navigate = useNavigate();
  const location = useLocation();

  const [bookingScreenData, setBookingScreenData] = useState<BookingScreenData | undefined>(undefined);
  const [workoutScreenData, setWorkoutScreenData] = useState<WorkoutScreenData | undefined>(undefined);
  const [movementScreenData, setMovementScreenData] = useState<MovementScreenData | undefined>(undefined);
  const [timerScreenData, setTimerScreenData] = useState<TimerScreenData | undefined>(undefined);

  const coreServiceRef = useRef<CoreService | null>(null);
  const renderServiceRef = useRef<RenderService | null>(null);

  const workoutScreenDataHandler = (workoutScreenData: WorkoutScreenData) => {
    setWorkoutScreenData(workoutScreenData);
  };

  const bookingScreenDataHandler = (bookingScreenData: BookingScreenData) => {
    setBookingScreenData(bookingScreenData);
  };

  const movementScreenDataHandler = (movementScreenData: MovementScreenData) => {
    setMovementScreenData(movementScreenData);
  };

  const timerScreenDataHandler = (timerScreenData: TimerScreenData) => {
    setTimerScreenData(timerScreenData);
  };

  const timerControlRef = useRef<TimerControl | null>(null);

  const handleAction = (action: TimerControlAction) => {
    if (timerControlRef.current) {
      switch (action) {
        case TimerControlAction.PLAY:
          timerControlRef.current.play();
          break;
        case TimerControlAction.STOP:
          timerControlRef.current.stop();
          break;
        case TimerControlAction.RESUME:
          timerControlRef.current.resume();
          break;
        case TimerControlAction.RESET:
          timerControlRef.current.reset();
          break;
        default:
          logger.error(`Action ${action} is not supported`);
          break;
      }
    }
  }


  useEffect(() => {
    const core = coreServiceRef.current = CoreService.getInstance();
    const renderService = renderServiceRef.current = RenderService.getInstance();
    renderService.addHandler<WorkoutScreenData>('WorkoutScreenData', workoutScreenDataHandler);
    renderService.addHandler<BookingScreenData>('BookingScreenData', bookingScreenDataHandler);
    renderService.addHandler<MovementScreenData>('MovementScreenData', movementScreenDataHandler);
    renderService.addHandler<TimerScreenData>('TimerScreenData', timerScreenDataHandler);

    core.retrieveTvData().then((tv) => {

      core.actionHandler = handleAction;

      const tvType = tv?.type;

      switch (tvType) {
        case TvTypeEnum.Exercise:
          navigate(`/exercise${location.search}`);
          break;
        case TvTypeEnum.Schedule:
          navigate(`/booking${location.search}`);
          break;
        case TvTypeEnum.Movement:
          navigate(`/movement${location.search}`);
          break;
        case TvTypeEnum.Timer:
          navigate(`/timer${location.search}`);
          break;
        case TvTypeEnum.Billboard:
        default:
          logger.debug('TvType is unknown');
          break;
      }
    });

    return () => {
      logger.debug("Cleanup: Remove handlers");
      //renderService.removeHandler('WorkoutScreenData', workoutScreenDataHandler);
      //renderService.removeHandler('BookingScreenData', bookingScreenDataHandler);
      //renderService.removeHandler('MovementScreenData', movementScreenDataHandler);
      //renderService.removeHandler('TimerScreenData', timerScreenDataHandler);
      
    };

  }, [navigate]);


  useEffect(() => {
    changeLanguage('pt');
  }, [i18n]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'pt';
    changeLanguage(savedLanguage);
  }, [i18n]);


  return (
    <div>
      <Routes>
      <Route path="/test" element={<HorNextClass />} />
        <Route path="/timer" element={<Timer timerScreenData={timerScreenData} timerControlRef={timerControlRef} />} />
        <Route path="/movement" element={<HorMovement movementScreenData={movementScreenData} timerControlRef={timerControlRef} />} />
        <Route path="/booking" element={<Booking screenData={bookingScreenData} timerControlRef={timerControlRef} />} />
        <Route path="/exercise" element={<Exercise screenData={workoutScreenData} timerControlRef={timerControlRef} />} />
      </Routes>
    </div>
  );
};

export default App;

