// CoreService.ts

import log from '../utils/logger.utils';
import { BookingClasses } from '../types/booking.type';
import { TvType, TvTypeEnum } from '../types/tvs.type';
import { ClassData, ClassSchedule } from '../types/class.type';
import { MqttEvents } from '../types/events.type';
import { ConfigService } from './config.service';
import { DateTimeUtils } from '../utils/datetime.utils';
import { ExerciseService } from './exercise.service';
import { RenderEventService } from './renderEvent.service';
import { ApiService } from './api.service';
import { ClassScheduleService } from './classSchedule.service';
import { MqttService } from './mqtt.service';
import { MqttMessageAction } from '../types/mqtt.type';
import { TimerControlAction } from '../types/timer.type';
import { DataFetchService } from './dataFetch.service';

export class CoreService {
    private static instance: CoreService;
    private logger: log.Logger;
    private config: ConfigService;
    private apiService: ApiService;
    private mqttService: MqttService;
    private dataFetchService: DataFetchService;
    private classData?: ClassData;
    private bookingData?: BookingClasses;
    private nextClass?: ClassSchedule;
    private currentClass?: ClassSchedule;
    private classInProgress = false;
    private currentExerciseService?: ExerciseService;
    public actionHandler?: (action: TimerControlAction) => void;
    private nextClassTimeout?: NodeJS.Timeout;

    // Constructor and Initialization Methods
    private constructor() {
        this.logger = log.getLogger('CoreService');
        this.config = ConfigService.getInstance();
        this.apiService = ApiService.getInstance(this.config);
        this.mqttService = MqttService.getInstance();
        this.dataFetchService = new DataFetchService(this.apiService, this.config);
        this.registerEventListeners();
        this.logger.info('CoreService initialized successfully.');
    }

    private registerEventListeners() {
        this.logger.debug('Registering MQTT event listeners.');
        this.mqttService.on(MqttEvents.onMessage, this.processMqttMessage.bind(this));
    }

    private initializeDataSubscriptions() {
        const tv = this.config.currentTv;
        if (!tv) {
            this.logger.error('TV configuration not found.');
            return;
        }

        switch (tv.type) {
            case TvTypeEnum.Timer:
            case TvTypeEnum.Exercise:
                this.logger.debug('Subscribing to class data updates.');

                // Define the data fetch function
                const fetchClassData = async () => {
                    try {
                        if (!this.classInProgress) {
                            const newClassData = await this.dataFetchService.refreshClassData();

                            if (newClassData) {
                                this.classData = newClassData;
                                this.handleClassSchedule();
                            }

                        }
                    } catch (error) {
                        this.logger.error('Error refreshing class data:', error);
                    }
                };

                // Fetch data immediately
                fetchClassData();

                // Set up interval for subsequent data fetches
                setInterval(fetchClassData, this.config.tvDataUpdateInterval);
                break;

            case TvTypeEnum.Schedule:
                this.logger.debug('Subscribing to booking data updates.');

                const fetchBookingData = async () => {
                    try {
                        const newBookingData = await this.dataFetchService.refreshBookingData();
                        if (newBookingData) {
                            this.bookingData = newBookingData;
                            RenderEventService.emitBookingDataEvent(this.bookingData);
                        }
                    } catch (error) {
                        this.logger.error('Error refreshing booking data:', error);
                    }
                };

                // Fetch data immediately
                fetchBookingData();

                // Set up interval for subsequent data fetches
                setInterval(fetchBookingData, this.config.tvDataUpdateInterval);
                break;

            case TvTypeEnum.Movement:
                this.logger.debug('Subscribing to movement data updates.');

                const fetchMovementData = async () => {
                    try {
                        const newMovementData = await this.dataFetchService.refreshMovementData();
                        if (newMovementData) {
                            this.classData = newMovementData;
                            let tempClassData = this.retrieveCurrentClassFromConfig();
                            this.currentClass = tempClassData ? tempClassData : ClassScheduleService.nextClassSchedule(this.classData);
                            if (this.currentClass) {
                                RenderEventService.emitMovementEvent(this.currentClass);
                            } else {
                                this.logger.warn('No current class found to emit movement event.');
                            }
                        }
                    } catch (error) {
                        this.logger.error('Error refreshing movement data:', error);
                    }
                };

                // Fetch data immediately
                fetchMovementData();

                // Set up interval for subsequent data fetches
                setInterval(fetchMovementData, this.config.tvDataUpdateInterval);
                break;

            default:
                this.logger.error('Invalid TV type:', tv.type);
                break;
        }
    }

    // MQTT Event Handling
    private processMqttMessage(channel: string, data: MqttMessageAction) {
        this.logger.info('Received MQTT event on channel', channel, 'with data:', data);
        if (!this.actionHandler) return;

        switch (data.action) {
            case 'play':
                this.logger.debug('Processing play action.');
                if (!this.classInProgress) {
                    this.currentClass = this.currentClass || this.nextClass;
                    if (this.currentClass) {
                        this.actionHandler(TimerControlAction.PLAY);
                        this.handleWorkoutStart();
                    } else {
                        this.logger.warn('Play action received, but no class is scheduled to start.');
                    }
                } else {
                    this.actionHandler(TimerControlAction.PLAY);
                    this.currentExerciseService?.play();
                }
                break;
            case 'pause':
                this.logger.debug('Processing pause action.');
                this.actionHandler(TimerControlAction.STOP);
                this.currentExerciseService?.pause();
                break;
            case 'restart':
                this.logger.debug('Processing restart action.');
                if (this.currentClass) {
                    if (this.classInProgress) {
                        this.currentExerciseService?.stop();
                        this.classInProgress = false;
                    }
                    this.actionHandler(TimerControlAction.PLAY);
                    this.startWorkoutSession(this.currentClass);
                } else {
                    this.logger.warn('Restart action received, but no class is available to restart.');
                }
                break;
            case 'stop':
                this.logger.debug('Processing stop action.');
                if (this.currentClass) {
                    if (this.classInProgress) {
                        this.currentExerciseService?.stop();
                        this.classInProgress = false;
                    }
                    this.actionHandler(TimerControlAction.PLAY);
                    this.dispatchNextWorkoutEvent();
                } else {
                    this.logger.warn('Stop action received, but no class is available to stop.');
                }
                break;
            case 'load': {
                this.logger.debug('Processing load action.'); 
                
                if (data.data && data.data.date) {
                    this.config.classScheduleDate = data.data.date;
                    this.classInProgress = false;
                    this.classData = undefined;
                    this.bookingData = undefined;
                    this.nextClass = undefined
                    this.currentClass = undefined;
                    this.currentExerciseService?.stop();
                    this.currentExerciseService = undefined;
                         // Define the data fetch function
                    if (this.config.currentTv?.type === TvTypeEnum.Timer || this.config.currentTv?.type === TvTypeEnum.Exercise) {

                         const fetchClassData = async () => {
                            try {
                                if (!this.classInProgress) {
                                    const newClassData = await this.dataFetchService.refreshClassData();
        
                                    if (newClassData) {
                                        this.classData = newClassData;
                                        this.handleClassSchedule();
                                    }
        
                                }
                            } catch (error) {
                                this.logger.error('Error refreshing class data:', error);
                            }
                        };

                        fetchClassData();

                    } else if (this.config.currentTv?.type === TvTypeEnum.Schedule) {
                        const fetchBookingData = async () => {
                            try {
                                const newBookingData = await this.dataFetchService.refreshBookingData();
                                if (newBookingData) {
                                    this.bookingData = newBookingData;
                                    RenderEventService.emitBookingDataEvent(this.bookingData);
                                }
                            } catch (error) {
                                this.logger.error('Error refreshing booking data:', error);
                            }
                        };
        
                        // Fetch data immediately
                        fetchBookingData();

                    } else if (this.config.currentTv?.type === TvTypeEnum.Movement) {
                        const fetchMovementData = async () => {
                            try {
                                const newMovementData = await this.dataFetchService.refreshMovementData();
                                if (newMovementData) {
                                    this.classData = newMovementData;
                                    let tempClassData = this.retrieveCurrentClassFromConfig();
                                    this.currentClass = tempClassData ? tempClassData : ClassScheduleService.nextClassSchedule(this.classData);
                                    if (this.currentClass) {
                                        RenderEventService.emitMovementEvent(this.currentClass);
                                    } else {
                                        this.logger.warn('No current class found to emit movement event.');
                                    }
                                }
                            } catch (error) {
                                this.logger.error('Error refreshing movement data:', error);
                            }
                        };
        
                        // Fetch data immediately
                        fetchMovementData();
                    }
                }
            }
                break;
            default:
                this.logger.warn('Received invalid MQTT action:', data.action);
                break;
        }
    }

    // Class Schedule Handling
    private handleClassSchedule() {
        if (this.classInProgress) {
            this.logger.trace('Class is currently in progress; skipping schedule handling.');
            return;
        }

        this.classData = this.dataFetchService.getClassData();
        if (!this.classData) {
            this.logger.warn('Cannot handle class schedule: No class data available.');
            return;
        }

        this.currentClass = this.retrieveCurrentClassFromConfig();

        if (this.currentClass) {
            this.logger.info('Current class found; starting immediately:', this.currentClass);
            this.handleWorkoutStart();
        } else {
            this.nextClass = ClassScheduleService.nextClassSchedule(this.classData);
            if (this.nextClass) {
                this.logger.info('Next class scheduled:', this.nextClass);
                this.dispatchNextWorkoutEvent();
                this.scheduleNextClassStart();
            } else {
                this.logger.warn('No upcoming classes found in the schedule.');
            }
        }
    }

    private retrieveCurrentClassFromConfig(): ClassSchedule | undefined {
        this.logger.debug('Attempting to get current class from configuration.');
        if (!this.classData) return undefined;

        const { classScheduleId: id, classScheduleTime: time } = this.config;

        if (time) {
            this.logger.debug('Using class schedule time from config:', time);
            return ClassScheduleService.getClassScheduleByTime(this.classData, time);
        } else if (id) {
            this.logger.debug('Using class schedule ID from config:', id);
            return ClassScheduleService.getFirstClassScheduleByClassId(this.classData, parseInt(id));
        } else {
            this.logger.warn('No class schedule found in configuration.');
            return undefined;
        }
    }

    private scheduleNextClassStart() {
        if (this.classInProgress || !this.nextClass) {
            return;
        }

        const timeToNextClassMs = this.calculateTimeUntilNextClass(this.nextClass);
        if (timeToNextClassMs > 0) {
            this.logger.info(`Scheduled next class in ${timeToNextClassMs} milliseconds.`);
            clearTimeout(this.nextClassTimeout);
            this.logger.debug('Setting timeout to start next class.');
            this.nextClassTimeout = setTimeout(() => {
                this.currentClass = this.nextClass;
                this.handleWorkoutStart();
            }, timeToNextClassMs);
        } else {
            this.logger.warn('Cannot schedule next class: class time is in the past or has already started.');
        }
    }

    private calculateTimeUntilNextClass(nextClass: ClassSchedule): number {
        const currentTime = Date.now();
        const classTime = DateTimeUtils.hoursToTime(nextClass.start_time).getTime();
        return Math.max(classTime - currentTime, 0);
    }

    // Workout Control
    private handleWorkoutStart() {
        if (this.currentClass) {
            this.logger.info('Starting class workout:', this.currentClass);
            this.startWorkoutSession(this.currentClass);
        } else {
            this.logger.warn('Attempted to start class workout, but no current class is scheduled.');
        }
    }

    private startWorkoutSession(classSchedule: ClassSchedule) {
        clearTimeout(this.nextClassTimeout);
        this.logger.debug('Clearing next class timeout.');

        const taskCallback = this.config.currentTv?.type === TvTypeEnum.Timer ? RenderEventService.emitTimerEvent : RenderEventService.emitExerciseTaskEvent;
        this.logger.debug('Initializing ExerciseService with class schedule:', classSchedule);
        this.currentExerciseService = new ExerciseService(
            classSchedule,
            this.config.exercisesTaskDelay,
            this.handleWorkoutCompletion.bind(this),
            taskCallback
        );
        this.logger.debug('Starting ExerciseService play.');
        this.currentExerciseService.play();
        this.classInProgress = true;
    }

    private handleWorkoutCompletion() {
        this.classInProgress = false;
        this.logger.info('Class workout completed successfully.');
        this.handleClassSchedule();
    }

    // Rendering Events
    private dispatchNextWorkoutEvent() {
        if (this.nextClass) {
            switch (this.config.currentTv?.type) {
                case TvTypeEnum.Exercise:
                    this.logger.debug('Emitting next workout event for Exercise TV.');
                    RenderEventService.emitExerciseNextWorkoutEvent(this.nextClass);
                    break;
                case TvTypeEnum.Timer:
                    this.logger.debug('Emitting next workout event for Timer TV.');
                    RenderEventService.emitTimerNextWorkoutEvent(this.nextClass);
                    break;
                default:
                    this.logger.error('Failed to emit next workout event: Invalid TV type in configuration.');
                    break;
            }
        }
    }

    /**
     * Returns an instance of the CoreService class.
     * If an instance does not exist, a new instance is created and returned.
     *
     * @returns {CoreService} The instance of the CoreService class.
     */
    public static getInstance(): CoreService {
        if (!CoreService.instance) {
            CoreService.instance = new CoreService();
        }
        return CoreService.instance;
    }

    /**
     * Retrieves TV data.
     * 
     * @returns {Promise<TvType>} A promise that resolves to the TV object.
     * @throws {Error} If TV is not found.
     */
    public async retrieveTvData(): Promise<TvType> {
        try {
            const tv = await this.config.getTvInfo();
            if (!tv) throw new Error('TV not found');

            this.logger.info(`Fetching data for TV ID ${tv.id} with type ${tv.type}.`);

            //await this.dataFetchService.startFetching();
            this.initializeDataSubscriptions();

            return tv;
        } catch (error) {
            this.logger.error('Error retrieving TV data:', error);
            throw error;
        }
    }
}