import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import "./ProgressCircle.css";
import { DateTimeUtils } from "../../utils/datetime.utils"; // Importing the function
import CircleProgressBar from "./CircleProgressBar";
import { TimerControl } from "../../types/timer.type";
import { t } from "i18next";

interface ProgressCircleProps {
  initialTotalTime: number;
  color?: "red" | "green" | "yellow" | "default";
  sound?: boolean;
  bypassCacheGuid?: string;
}

const ProgressCircle = forwardRef<TimerControl, ProgressCircleProps>(({
  initialTotalTime,
  color,
  sound = true,
  bypassCacheGuid,
}, ref) => {

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const [totalTime, setTotalTime] = useState(initialTotalTime);
  const [timeLeft, setTimeLeft] = useState(initialTotalTime);
  const [paused, setPaused] = useState(false);
  const [bgColor, setBgColor] = useState("#E0E0E0");
  const [progressColor, setProgressColor] = useState("#FF5733");
  const [fontSize, setFontSize] = useState("var(--title-hero-font-size)");
  const audioRef = useRef<HTMLAudioElement | null>(null); // Reference to audio element
  const [bypassCache, setCacheGuid] = useState(bypassCacheGuid);

  const updateColorsAndFontSize = useCallback(() => {
    setFontSize(
      timeLeft > 3600
        ? "var(--font-fontSize-3xl, 40px)"
        : "var(--font-fontSize-3xl, 72px)"
    );
  }, [timeLeft]);

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current); // Clear any existing interval when initialTotalTime changes
    }

    const delayStart = setTimeout(() => {
      intervalIdRef.current = setInterval(() => {
        if (!paused) {
          setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
        }
      }, 1000);
    }, 150);

    return () => {
      clearTimeout(delayStart); // Clear the timeout if the component unmounts
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current); // Clear the interval if the component unmounts
      }
    };
  }, [initialTotalTime, paused]);

  useEffect(() => {
    updateColorsAndFontSize();

    if (sound && timeLeft <= 1 && audioRef.current) {
      audioRef.current.play();
    }

  }, [timeLeft, updateColorsAndFontSize]);

  useEffect(() => {
    setCacheGuid(bypassCacheGuid);
    setTotalTime(initialTotalTime);
    setTimeLeft(initialTotalTime);
  }, [initialTotalTime, bypassCacheGuid]);

  // useImperativeHandle exposes functions to parent components via ref
  useImperativeHandle(ref, () => ({
    stop: () => setPaused(true),
    play: () => setPaused(false),
    resume: () => setPaused(false),
    reset: () => {
      setPaused(false);
      setTimeLeft(initialTotalTime);
      setTotalTime(initialTotalTime);
    },
    setTime: (newTime: number) => {
      setPaused(true);
      setTimeLeft(newTime);
      setTotalTime(newTime);
      setPaused(false);
    },
  }));


  useEffect(() => {
    if (color === "default" || !color) {
      // Apply default colors here
      setBgColor("#E0E0E0");
      setProgressColor("#E43B3D");
    }
    else if (color === "red" && timeLeft < 10) {
      setBgColor("#F7E49D");
      setProgressColor("#836C18");
   } else if (color === "red") {
      setBgColor("#E7E7E7");
      setProgressColor("#7D2022");
   } else if (color === "green") {
      setBgColor("#E7E7E7");
      setProgressColor("#2B5A38");
   } else if (color === "yellow") {
      setBgColor("#F7E49D");
      setProgressColor("#836C18");
   } else {
      // Default color case
      setBgColor("#1B1B1B");
      setProgressColor("#E43B3D");
   }
  }, [color, timeLeft, initialTotalTime]);



  const progress = ((totalTime - timeLeft) / totalTime) * 100;

  const timeLeftColorClass =
    (color === "yellow" || (color === "red" && timeLeft < 10))
      ? "div yellow"
      : (color === "red"
        ? "div red"
        : "div");

  const circleColorClass =
    color === "yellow" || (color === "red" && timeLeft < 10)
      ? "content yellow"
      : color === "green"
        ? "content green"
        : color === "red"
          ? "content red"
          : "content";

  return (
    <div key={bypassCacheGuid}>
      <div className="progress-circle">
        <div className={circleColorClass}>
          <div className="element">
            <CircleProgressBar
              progress={progress}
              bgColor={bgColor}
              progressColor={progressColor}
            />
            <div className="overlap-group">
              <div className="text-wrapper">
                <div className={timeLeftColorClass} style={{ fontSize }}>
                  {DateTimeUtils.formatTime(timeLeft)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <audio ref={audioRef} src="/sound/ding.mp3" /> {/* Add your sound file */}
      </div>
    </div>
    );
  }
);

export default ProgressCircle;